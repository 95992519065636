body {
  background-color: #f5f5f5;
}

.loading-effect-container {
  display: block;
  overflow: hidden;
}

.loading-effect {
  position: relative;
  background-color: #e2e2e2;
}

.loading-effect::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: loading 1.25s infinite;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.loading-effect-strip-1 {
  height: 1rem;
  display: block;
}

.loading-effect-strip-2 {
  height: 1.5rem;
  display: block;
  margin-top: 8px;
}
